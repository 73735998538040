import React from 'react';
import { Navigate, Link } from 'react-router-dom';

const SecretMenu = ({ isAuthenticated }) => {
  if (!isAuthenticated) {
    return <Navigate to="/login" replace />;
  }

  return (
    <div style={{ backgroundColor: '#FFD700', fontFamily: 'Arial, sans-serif', padding: '20px', textAlign: 'center' }}>
      <h1 style={{ color: '#FF0000', textShadow: '2px 2px #000000', fontSize: '36px' }}>Matt's web apps</h1>
      <marquee style={{ backgroundColor: '#FF0000', color: '#FFFFFF', padding: '10px' }}>Welcome!</marquee>
      <table style={{ width: '100%', borderCollapse: 'collapse', marginTop: '20px' }}>
        <tr style={{ backgroundColor: '#FF0000', color: '#FFFFFF' }}>
          <th style={{ border: '2px solid #000000', padding: '10px' }}>App</th>
          <th style={{ border: '2px solid #000000', padding: '10px' }}>Price</th>
        </tr>
        <tr>
          <td style={{ border: '2px solid #000000', padding: '10px' }}>
            <a href="/chat" style={{ color: '#0000FF' }}>Chat</a>
          </td>
          <td style={{ border: '2px solid #000000', padding: '10px' }}>free</td>
        </tr>
        <tr>
          <td style={{ border: '2px solid #000000', padding: '10px' }}>
            <Link to="/pdfsummarizer" style={{ color: '#0000FF' }}>PDF Summarizer</Link>
          </td>
          <td style={{ border: '2px solid #000000', padding: '10px' }}>free</td>
        </tr>
        <tr>
          <td style={{ border: '2px solid #000000', padding: '10px' }}>identity graph generator</td>
          <td style={{ border: '2px solid #000000', padding: '10px' }}>$10.99</td>
        </tr>
      </table>
      <div style={{ marginTop: '20px' }}>
        {/* <img src="./assets/dragon.gif" alt="Animated dragon" style={{ width: '100px' }} /> */}
        <p style={{ color: '#FF0000', fontWeight: 'bold' }}>Order now: 313-451-4516</p>
        <blink style={{ color: '#0000FF' }}>Free fortune cookie with every order!</blink>
      </div>
    </div>
  );
};

export default SecretMenu;
