import React from 'react';
import { User, Briefcase, FileText, Mail, Twitter, Linkedin } from 'lucide-react';
import { styles } from './styles';
import { InfoCard, Button, Input } from './components/Components';

const Resume = () => {
  return (
    <div className="bg-gray-100 min-h-screen">
      <div className="max-w-4xl mx-auto p-8 bg-white shadow-lg">

        <header className="flex flex-col md:flex-row items-center mb-12">
          <img src="/profile-picture.png" alt="Profile picture" className="w-60 h-60 rounded-full object-cover mb-4 md:mb-0 md:mr-8" />
          <div>
            <h1 className="text-4xl font-bold mb-6">Hi, I'm Matt Burtell</h1>
            <p className="text-lg mb-4" style={{ textAlign: 'left' }}>I work on AI policy at the Center for Security and Emerging Technology.</p>
            <p className="text-lg" style={{ textAlign: 'left' }}>
              I have a BS in Computer Science and Mathematics from Yale, and I served four years in the US Navy where I fixed computers and computer networks on the aircraft carrier USS Ronald Reagan.
            </p>
          </div>
        </header>

        <main style={{ textAlign: 'left' }}>
          <h2 className="text-2xl font-semibold mb-2">Academic papers, op-eds, and other writing</h2>
          <table style={{ width: '100%' }}>
            <tbody>

              <tr>
                <td style={{ width: '56px', textAlign: 'left', paddingRight: '10px', paddingBottom: '4px', paddingTop: '6px', fontSize: '0.72em' }}>2024</td>
                <td style={{ paddingBottom: '3px', paddingTop: '6px' }}>
                  <a
                    href="https://www.lawfaremedia.org/article/for-government-use-of-ai-what-gets-measured-gets-managed"
                    style={{ color: '#191970' }}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="hover:text-gray-500 hover:underline"
                  >
                    Op-ed in Lawfare on AI procurement and AI evaluations
                  </a>
                </td>
              </tr>





              <tr>
                <td style={{ width: '56px', textAlign: 'left', paddingRight: '10px', paddingBottom: '4px', paddingTop: '6px', fontSize: '0.72em' }}>2024</td>
                <td style={{ paddingBottom: '3px', paddingTop: '6px' }}>
                  <a
                    href="https://cset.georgetown.edu/article/the-surprising-power-of-next-word-prediction-large-language-models-explained-part-1/"
                    style={{ color: '#191970' }}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="hover:text-gray-500 hover:underline"
                  >
                    AI pre-training simply explained
                  </a>
                </td>
              </tr>

              <tr>
                <td style={{ width: '56px', textAlign: 'left', paddingRight: '10px', paddingBottom: '4px', paddingTop: '6px', fontSize: '0.72em' }}>2023</td>
                <td style={{ paddingBottom: '3px', paddingTop: '6px' }}>
                  <a
                    href="https://arxiv.org/pdf/2303.08721"
                    style={{ color: '#191970' }}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="hover:text-gray-500 hover:underline"
                  >
                    Artificial Influence: An Analysis Of AI-Driven Persuasion
                  </a>
                </td>
              </tr>
              <tr>
                <td style={{ width: '56px', textAlign: 'left', paddingRight: '10px', paddingBottom: '4px', paddingTop: '6px', fontSize: '0.72em' }}>2022</td>
                <td style={{ paddingBottom: '3px', paddingTop: '6px' }}>
                  <a
                    href="https://arxiv.org/pdf/2209.00840"
                    style={{ color: '#191970' }}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="hover:text-gray-500 hover:underline"
                  >
                    FOLIO: Natural Language Reasoning with First-Order Logic
                  </a>
                </td>
              </tr>
              <tr>
                <td style={{ width: '56px', textAlign: 'left', paddingRight: '10px', paddingBottom: '4px', paddingTop: '6px', fontSize: '0.72em' }}>2021</td>
                <td style={{ paddingBottom: '3px', paddingTop: '6px' }}>
                  <span style={{ color: '#191970' }} className="hover:text-gray-500">
                    Parameter, compute and data trends in machine learning
                  </span>
                </td>
              </tr>
            </tbody>
          </table>

          <h2 className="text-2xl font-semibold mb-2 mt-8">Experiences</h2>
          <table style={{ width: '100%' }}>
            <tbody>
              <tr>
                <td style={{ width: '56px', textAlign: 'left', paddingRight: '10px', paddingBottom: '4px', paddingTop: '6px', fontSize: '0.72em' }}>August 2023 - Present</td>
                <td style={{ paddingBottom: '3px', paddingTop: '6px' }}>Grant evaluator and researcher, Center for Security and Emerging Technology at Georgetown University</td>
              </tr>
              <tr>
                <td style={{ width: '56px', textAlign: 'left', paddingRight: '10px', paddingBottom: '4px', paddingTop: '6px', fontSize: '0.72em' }}>Summer 2021 - Spring 2022</td>
                <td style={{ paddingBottom: '3px', paddingTop: '6px' }}>Undergraduate Research Assistant, LILY Lab at Yale University</td>
              </tr>
              <tr>
                <td style={{ width: '56px', textAlign: 'left', paddingRight: '10px', paddingBottom: '4px', paddingTop: '6px', fontSize: '0.72em' }}>Summer 2021</td>
                <td style={{ paddingBottom: '3px', paddingTop: '6px' }}>Software Engineering Intern, Quantum Signal AI (Subsidiary of Ford Autonomous Vehicles)</td>
              </tr>
              <tr>
                <td style={{ width: '56px', textAlign: 'left', paddingRight: '10px', paddingBottom: '4px', paddingTop: '6px', fontSize: '0.72em' }}>Summer 2020</td>
                <td style={{ paddingBottom: '3px', paddingTop: '6px' }}>Security Intern, Quantum Signal AI (Subsidiary of Ford Autonomous Vehicles)</td>
              </tr>
              <tr>
                <td style={{ width: '56px', textAlign: 'left', paddingRight: '10px', paddingBottom: '4px', paddingTop: '6px', fontSize: '0.72em' }}>2019</td>
                <td style={{ paddingBottom: '3px', paddingTop: '6px' }}>Lead Group Study Facilitator and Tutor, Washtenaw Community College</td>
              </tr>
              <tr>
                <td style={{ width: '56px', textAlign: 'left', paddingRight: '10px', paddingBottom: '4px', paddingTop: '6px', fontSize: '0.72em' }}>2014 - 2018</td>
                <td style={{ paddingBottom: '3px', paddingTop: '6px' }}>
	  Information Systems Technician (E1 to E5), US Navy
	  </td>
              </tr>
            </tbody>
          </table>
        </main>

        <footer className="text-center text-gray-500 mt-12">
          <p>© {new Date().getFullYear()} Matthew Thaddeus Burtell. All rights reserved.</p>
        </footer>
      </div>
    </div>
  );
};

export default Resume;
