import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { useDropzone } from 'react-dropzone';
import { Box, Button, Container, Flex, Heading, Text, VStack, useToast, Stack } from '@chakra-ui/react';
import ReactMarkdown from 'react-markdown';
import useAuth from './hooks/useAuth';
import usePDFSummarizer from './hooks/usePDFSummarizer';
import GoogleSignIn from './components/GoogleSignIn';
import { SummaryBox } from './components/StyledComponents';
import RecentSummaries from './components/RecentSummaries';
// import { FiClock } from 'react-icons/fi';
import { extendTheme, ChakraProvider } from '@chakra-ui/react';

const PDFSummarizer = ({ isAuthenticated }) => {
  const { isGoogleAuthenticated, userUid } = useAuth();
  const toast = useToast();
  const {
    pdfText,
    streamedSummary,
    isLoading,
    file,
    recentSummaries,
    fetchRecentSummaries,
    handleFileDrop,
    handleClear,
    handleSummarize,
    setStreamedSummary,
    error,
    setError
  } = usePDFSummarizer(userUid);

  const [selectedSummary, setSelectedSummary] = useState(null);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: handleFileDrop,
    accept: 'application/pdf',
    multiple: false
  });

  const handleSummaryClick = (summary) => {
    setSelectedSummary(summary);
    setStreamedSummary(summary.summary);
    setError(null);
  };

  useEffect(() => {
    if (userUid) {
      fetchRecentSummaries().catch(err => {
        setError(err.message);
        toast({
          title: "Error",
          description: err.message,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      });
    }
  }, [userUid, fetchRecentSummaries, setError, toast]);

  if (!isAuthenticated) {
    return <Navigate to="/login" replace />;
  }

  if (!isGoogleAuthenticated) {
    return (
      <Container maxW="container.xl" centerContent>
        <GoogleSignIn />
      </Container>
    );
  }

  return (
    <ChakraProvider>
      <Container maxW="container.xl" p={4} bg="green.200" minH="100vh">
        <Stack direction={{ base: 'column', md: 'row' }} spacing={4}>
          <Box w={{ base: 'full', md: '250px' }} mb={{ base: 4, md: 0 }}>
            <Box
              {...getRootProps()}
              borderWidth={2}
              borderStyle="dashed"
              borderRadius="md"
              p={4}
              mb={4}
              textAlign="center"
              bg={isDragActive ? "blue.50" : "gray.50"}
              _hover={{ bg: "blue.50" }}
            >
              <input {...getInputProps()} />
              {file ? (
                <VStack>
                  <Text fontSize="sm">File: {file.name}</Text>
                  <Button onClick={handleClear} isDisabled={isLoading} colorScheme="red" size="xs">
                    Clear
                  </Button>
                </VStack>
              ) : (
                <Text fontSize="sm">{isDragActive ? 'Drop the PDF here' : 'Drag & drop a PDF file here, or click to select one'}</Text>
              )}
            </Box>
            <Box display={{ base: 'none', md: 'block' }}>
              <RecentSummaries
                summaries={recentSummaries}
                onSummaryClick={handleSummaryClick}
                selectedSummaryId={selectedSummary ? selectedSummary.id : null}
              />
            </Box>
          </Box>
          <Flex flex={1} direction="column">
            <Box>
              <Heading as="h2" size="lg" mb={4}>Summary</Heading>
              {error && (
                <Box bg="red.100" color="red.700" p={3} borderRadius="md" mb={4}>
                  {error}
                </Box>
              )}
              {isLoading && <Box textAlign="center" my={4}>Loading...</Box>}
              {streamedSummary ? (
                <SummaryBox overflowX="auto">
                  <ReactMarkdown>{streamedSummary}</ReactMarkdown>
                </SummaryBox>
              ) : (
                <Text textAlign="left">Upload a PDF and click 'Summarize' to see its summary here, or select a recent summary from the left.</Text>
              )}
              <Button
                onClick={handleSummarize}
                isDisabled={!pdfText || isLoading}
                colorScheme="blue"
                mt={4}
              >
                {isLoading ? 'Processing...' : 'Summarize'}
              </Button>
            </Box>
          </Flex>
        </Stack>
      </Container>
    </ChakraProvider>
  );
};

export default PDFSummarizer;