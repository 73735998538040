import { createClient } from '@supabase/supabase-js';

const supabaseUrl = 'https://nmjsvexxqlkhmzfuioez.supabase.co';
const supabaseKey = process.env.REACT_APP_SUPABASE_KEY || 'fallback-key';
const supabase = createClient(supabaseUrl, supabaseKey);

export const getSession = async () => {
  return await supabase.auth.getSession();
};

export const signInWithGoogle = async () => {
  return await supabase.auth.signInWithOAuth({ provider: 'google' });
};

export const onAuthStateChange = (callback) => {
  return supabase.auth.onAuthStateChange(callback);
};

export const uploadFile = async (userId, file) => {
  const { data, error } = await supabase.storage
    .from('private_pdf_bucket')
    .upload(`${userId}/${file.name}`, file, {
      cacheControl: '3600',
      upsert: false
    });

  if (error) throw error;
  return data;
};

export const fetchRecentSummaries = async (userId) => {
  const { data, error } = await supabase
    .from('pdf_summaries')
    .select('id, file_name, summary')
    .eq('user_id', userId)
    .order('created_at', { ascending: false })
    .limit(8);

  if (error) throw error;
  return data;
};

export const updatePdfSummariesTable = async (userId, fileName, filePath, summary) => {
  const { data, error } = await supabase
    .from('pdf_summaries')
    .insert([
      {
        user_id: userId,
        file_name: fileName,
        file_path: filePath,
        summary: summary
      }
    ]);

  if (error) throw error;
  return data;
};
