import React from 'react';
import { signInWithGoogle } from '../services/supabaseService';
import { Button } from './StyledComponents';

const GoogleSignIn = () => {
  const handleGoogleSignIn = async () => {
    try {
      await signInWithGoogle();
    } catch (error) {
      console.error('Error signing in with Google:', error);
      // You might want to add some user feedback here
    }
  };

  return (
    <div style={{ textAlign: 'center', padding: '20px' }}>
      <h2>Please sign in with Google to continue</h2>
      <Button onClick={handleGoogleSignIn}>Sign in with Google</Button>
    </div>
  );
};

export default GoogleSignIn;
