import styled, { keyframes } from 'styled-components';


const Container = styled.div`
  display: flex;
  height: 100vh;
  font-family: 'Arial', sans-serif;
`;

const DropZone = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-right: 1px solid #e0e0e0;
  padding: 20px;
  background-color: ${props => props.isDragActive ? '#e6f7ff' : '#f5f5f5'};
  transition: background-color 0.3s ease;
`;

const SummarySection = styled.div`
  flex: 2;
  padding: 20px;
  overflow-y: auto;
`;

const FileInfo = styled.div`
  margin-top: 20px;
  text-align: center;
`;

const Button = styled.button`
  background-color: #1890ff;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 10px;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #40a9ff;
  }

  &:disabled {
    background-color: #d9d9d9;
    cursor: not-allowed;
  }
`;

const SummaryBox = styled.div`
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 16px;
  margin-top: 16px;
  background-color: #f9f9f9;
  text-align: left;

  & > * {
    margin: 0;
  }

  & > * + * {
    margin-top: 16px;
  }

  ul, ol {
    padding-left: 20px;
  }
`;

const RecentSummariesList = styled.div`
  width: 200px;
  padding: 20px;
  border-right: 1px solid #e0e0e0;
  overflow-y: auto;
`;

const RecentSummaryItem = styled.div`
  padding: 10px;
  margin-bottom: 5px;
  cursor: pointer;
  background-color: ${props => props.isSelected ? '#e6f7ff' : 'transparent'};
  border-radius: 4px;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #f0f0f0;
  }
`;

const ErrorMessage = styled.div`
  color: #ff4d4f;
  background-color: #fff2f0;
  border: 1px solid #ffccc7;
  border-radius: 4px;
  padding: 8px 12px;
  margin-bottom: 16px;
  font-size: 14px;
`;

const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

const LoadingSpinner = styled.div`
  border: 4px solid #f3f3f3;
  border-top: 4px solid #1890ff;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: ${spin} 1s linear infinite;
  margin: 20px auto;
`;

export { Container, DropZone, SummarySection, FileInfo, Button, SummaryBox, RecentSummariesList, RecentSummaryItem, ErrorMessage, LoadingSpinner };
