import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import './App.css';

import Resume from './Resume';
import Login from './Login';
import Chat from './Chat';
import SecretMenu from './SecretMenu';
import PDFSummarizer from './PDFSummarizer'; // Import the new component

import { ChakraProvider } from '@chakra-ui/react'

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const storedApiKey = localStorage.getItem('apiKey');
    if (storedApiKey) {
      verifyApiKey(storedApiKey);
    } else {
      setLoading(false);
    }
  }, []);

  const verifyApiKey = async (apiKey) => {
    try {
      const response = await fetch('/.netlify/functions/verifyApiKey', {
        method: 'POST',
        body: JSON.stringify({ apiKey }),
      });
      const data = await response.json();
      setIsAuthenticated(data.success);
    } catch (err) {
      console.error('Error verifying API key:', err);
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <ChakraProvider>
      <Router>
        <div className="App">
          <Routes>
            <Route path="/" element={<Resume />} />
            <Route path="/login" element={<Login setIsAuthenticated={setIsAuthenticated} />} />
            <Route 
              path="/chat" 
              element={
                isAuthenticated ? <Chat /> : <Navigate to="/login" replace />
              } 
            />
            <Route 
              path="/secretmenu" 
              element={<SecretMenu isAuthenticated={isAuthenticated} />} 
            />
            <Route 
              path="/pdfsummarizer" 
              element={
                isAuthenticated ? <PDFSummarizer isAuthenticated={isAuthenticated} /> : <Navigate to="/login" replace />
              } 
            />
          </Routes>
        </div>
      </Router>
    </ChakraProvider>
  );
}

export default App;