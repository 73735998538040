import { useState, useEffect, useCallback } from 'react';
import * as supabaseService from '../services/supabaseService';

const useAuth = () => {
  const [isGoogleAuthenticated, setIsGoogleAuthenticated] = useState(false);
  const [userUid, setUserUid] = useState(null);

  const checkGoogleAuthStatus = useCallback(async () => {
    const { data: { session } } = await supabaseService.getSession();
    if (session?.user) {
      setIsGoogleAuthenticated(true);
      setUserUid(session.user.id);
      console.log('User already signed in with UID:', session.user.id);
    } else {
      setIsGoogleAuthenticated(false);
      setUserUid(null);
    }
  }, []);

  useEffect(() => {
    checkGoogleAuthStatus();
    
    const { data: authListener } = supabaseService.onAuthStateChange((event, session) => {
      if (event === 'SIGNED_IN' && session?.user) {
        setIsGoogleAuthenticated(true);
        setUserUid(session.user.id);
        console.log('User signed in with UID:', session.user.id);
      } else if (event === 'SIGNED_OUT') {
        setIsGoogleAuthenticated(false);
        setUserUid(null);
      }
    });

    return () => {
      authListener.subscription.unsubscribe();
    };
  }, [checkGoogleAuthStatus]);

  const handleGoogleSignIn = async () => {
    try {
      const { error } = await supabaseService.signInWithGoogle();
      if (error) throw error;
      // The auth state change listener will update the state
    } catch (error) {
      console.error('Error signing in with Google:', error);
      throw error;
    }
  };

  return {
    isGoogleAuthenticated,
    userUid,
    handleGoogleSignIn
  };
};

export default useAuth;
