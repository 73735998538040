// styles.js

export const styles = {
  container: "bg-gray-100 min-h-screen",
  innerContainer: "max-w-4xl mx-auto p-8 bg-white shadow-lg",
  nav: "flex flex-wrap justify-center mb-8",
  navLink: "mx-2 my-1 px-3 py-2 text-gray-700 hover:text-gray-900 hover:bg-gray-200 rounded transition duration-300",
  header: "flex flex-col md:flex-row items-center mb-12",
  profilePicture: "w-60 h-60 rounded-full object-cover mb-4 md:mb-0 md:mr-8",
  sectionTitle: "text-3xl font-semibold mb-6",
  projectCard: "bg-gray-50 p-6 rounded-lg shadow-md mb-6",
  projectTitle: "text-2xl font-medium mb-2",
  contactItem: "flex items-center mb-3",
  footer: "text-center text-gray-500 mt-12",
};

export const InfoCard = ({ title, subtitle, imageUrl }) => {
  return (
    <div className="bg-gray-50 p-6 rounded-lg shadow-md mb-6 flex">
      {imageUrl && (
        <div className="flex-shrink-0 mr-4">
          <img src={imageUrl} alt={title} className="w-24 h-24 object-cover rounded" />
        </div>
      )}
      <div>
        <h3 className="text-2xl font-medium mb-2">{title}</h3>
        <p className="text-gray-700">{subtitle}</p>
      </div>
    </div>
  );
};
