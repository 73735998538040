import { useState, useCallback } from 'react';
import pdfToText from 'react-pdftotext';
import * as supabaseService from '../services/supabaseService';

const usePDFSummarizer = (userUid) => {
  const [pdfText, setPdfText] = useState('');
  const [streamedSummary, setStreamedSummary] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [file, setFile] = useState(null);
  const [recentSummaries, setRecentSummaries] = useState([]);
  const [error, setError] = useState(null);

  const fetchRecentSummaries = useCallback(async () => {
    if (!userUid) return;
    
    try {
      const data = await supabaseService.fetchRecentSummaries(userUid);
      setRecentSummaries(data);
    } catch (error) {
      console.error("Error fetching recent summaries:", error);
    }
  }, [userUid]);

  const uploadFile = async (file) => {
    try {
      const data = await supabaseService.uploadFile(userUid, file);
      console.log('File uploaded successfully:', data);
      return data;
    } catch (error) {
      console.error("Error uploading file:", error);
      throw error;
    }
  };

  const summarizeText = async (text) => {
    const response = await fetch('/.netlify/functions/summarize', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ text }),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    return data.summary;
  };

  const updatePdfSummariesTable = async (fileName, filePath, summary) => {
    try {
      await supabaseService.updatePdfSummariesTable(userUid, fileName, filePath, summary);
      console.log("pdf_summaries table updated successfully");
      await fetchRecentSummaries();
    } catch (error) {
      console.error("Error updating pdf_summaries table:", error);
      throw error;
    }
  };

  const handleFileDrop = useCallback(async (acceptedFiles) => {
    const uploadedFile = acceptedFiles[0];
    if (uploadedFile && uploadedFile.type === 'application/pdf') {
      setFile(uploadedFile);
      setIsLoading(true);
      setError(null); // Clear any previous errors

      try {
        const text = await pdfToText(uploadedFile);
        setPdfText(text);

        const [uploadResult, summary] = await Promise.all([
          uploadFile(uploadedFile),
          summarizeText(text)
        ]);

        await updatePdfSummariesTable(uploadedFile.name, uploadResult.path, summary);

        setStreamedSummary(summary);
      } catch (error) {
        console.error("Error processing, uploading, or summarizing PDF:", error);
        setError(error.message); // Set the error message
      } finally {
        setIsLoading(false);
      }
    } else {
      setError('Please upload a PDF file.'); // Set error for invalid file type
    }
  }, [userUid]);

  const handleClear = () => {
    setFile(null);
    setPdfText('');
    setStreamedSummary('');
  };

  const handleSummarize = async () => {
    if (!pdfText) {
      throw new Error('Please upload a PDF file first.');
    }
    
    setIsLoading(true);
    setStreamedSummary('');

    try {
      const summary = await summarizeText(pdfText);
      setStreamedSummary(summary);
    } catch (error) {
      console.error('Error during summarization:', error);
      throw error;
    } finally {
      setIsLoading(false);
    }
  };

  return {
    pdfText,
    streamedSummary,
    isLoading,
    file,
    recentSummaries,
    fetchRecentSummaries,
    handleFileDrop,
    handleClear,
    handleSummarize,
    setStreamedSummary,
    error,
    setError
  };
};

export default usePDFSummarizer;
