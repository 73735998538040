import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const Chat = () => {
  const [message, setMessage] = useState('');
  const [chatHistory, setChatHistory] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedModel, setSelectedModel] = useState('claude-3-sonnet-20240229'); // Default model

  const handleModelChange = (model) => {
    setSelectedModel(model);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      const response = await fetch('/.netlify/functions/chat', {
        method: 'POST',
        body: JSON.stringify({ 
          message, 
          model: selectedModel, 
          history: chatHistory 
        }), 
      });
      const data = await response.json();

      setChatHistory([...chatHistory, { role: "user", content: message }, { role: "assistant", content: data.reply }]);
      setMessage('');
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="min-h-screen flex flex-col bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
      <div className="flex">
        <div className="w-1/4 bg-white shadow-md rounded-lg p-4">
          <h2 className="text-lg font-bold mb-2">Select Model</h2>
          <button 
            onClick={() => handleModelChange('claude-3-sonnet-20240229')} 
            className={`w-full mt-2 p-2 text-white rounded ${selectedModel === 'claude-3-sonnet-20240229' ? 'bg-blue-700 border-2 border-blue-900' : 'bg-blue-500'}`}
          >
            Claude
          </button>
          <button 
            onClick={() => handleModelChange('gpt-4o-mini')} 
            className={`w-full mt-2 p-2 text-white rounded ${selectedModel === 'gpt-4o-mini' ? 'bg-green-700 border-2 border-green-900' : 'bg-green-500'}`}
          >
            GPT-4o-mini
          </button>
        </div>
        <div className="w-3/4 ml-4">
          <div className="max-w-3xl w-full mx-auto space-y-8">
            <h1 className="text-center text-3xl font-extrabold text-gray-900">
              Chat
            </h1>
            <div className="bg-white shadow-sm rounded-lg p-6">
              <div className="space-y-4 mb-4">
                {chatHistory.map((chat, index) => (
                  <div key={index}>
                    <p className={`font-semibold ${chat.role === 'user' ? 'text-blue-600' : 'text-green-600'}`}>
                      {chat.role === 'user' ? 'You' : 'AI'}: {chat.content}
                    </p>
                  </div>
                ))}
              </div>
              <form onSubmit={handleSubmit} className="mt-4">
                <input
                  type="text"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  className="w-full px-3 py-2 border border-gray-300 rounded-md"
                  placeholder="Type your message..."
                  disabled={isLoading}
                />
                <button
                  type="submit"
                  className="mt-2 w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  disabled={isLoading}
                >
                  {isLoading ? 'Sending...' : 'Send'}
                </button>
              </form>
            </div>
            <div className="mt-4">
              <Link
                to="/"
                className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
              >
                Back to Home
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Chat;