import React from 'react';
import { Box, Text, VStack, Link, Icon, useColorModeValue } from '@chakra-ui/react';
import { FiFile } from 'react-icons/fi';

const RecentSummaries = ({ summaries, onSummaryClick, selectedSummaryId, onFileUpload }) => {
  return (
    <Box
      bg={useColorModeValue('green.50', 'gray.900')}
      w="full"
      h="100vh"
      overflowY="auto"
    >
      <Text fontSize="2xl" fontFamily="monospace" fontWeight="bold" p={4}>
        Recents
      </Text>
      {onFileUpload && (
        <Box p={4}>
          {onFileUpload}
        </Box>
      )}
      <VStack align="stretch" spacing={0}>
        {summaries.map((summary) => (
          <Box
            key={summary.id}
            as="a"
            href="#"
            style={{ textDecoration: 'none' }}
            _focus={{ boxShadow: 'none' }}
          >
            <Link
              onClick={() => onSummaryClick(summary)}
              _hover={{
                textDecoration: 'none',
              }}
              fontWeight={selectedSummaryId === summary.id ? "bold" : "normal"}
            >
              <Box
                display="flex"
                alignItems="center"
                p="4"
                mx="1"
                borderRadius="lg"
                role="group"
                cursor="pointer"
                _hover={{
                  bg: 'darkgreen',
                  color: 'white',
                }}
              >
                <Icon
                  mr="2"
                  fontSize="16"
                  flexShrink={0}
                  as={FiFile}
                />
                <Text fontSize="sm" isTruncated>
                  {summary.file_name}
                </Text>
              </Box>
            </Link>
          </Box>
        ))}
      </VStack>
    </Box>
  );
};

export default RecentSummaries;
